import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  errorNotification,
  loadingNotification,
  updateNotificationError,
  updateNotificationSuccess,
} from '../shared/utils';

export const apiWrapper = async (fn, successMessage, errorMessage) => {
  const id = loadingNotification('Please wait...');
  return fn
    .then(response => {
      updateNotificationSuccess(id, successMessage);
      return response.data;
    })
    .catch(err => {
      updateNotificationError(id, errorMessage);
      return err;
    });
};
