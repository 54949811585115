import React from "react";

export default function Subsribe() {
  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          src="assets/img/footer_wave.png"
          alt="footer shape"
          className="img-fluid"
        />
      </div>
    </>
  );
}
