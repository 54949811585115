import styled from 'styled-components';
import colors from './colors';


export const MenuFont = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1rem;
    font-weight: 400;

    :hover {
        color: ${colors.lightBlue};
    }
`;

export const ButtonText = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 700;
`;

export const BodyHeader30 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.2rem;
    font-weight: 700;
    color: ${colors.mainBlue}
`;

export const BodyHeader24 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: ${colors.mainBlue}
`;

export const BodyHeader18 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: ${colors.mainBlue}
`;

export const BodyText12 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: ${colors.grey}
`;

export const PromoHeader12 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: ${colors.mainBlue}
`;

export const ContactHeader = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 700;
    color: ${colors.mainBlue}
`;

export const BodyText10 = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: ${colors.grey}
`;