import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getCaptchaToken, sendCvForm } from '../../services/api/apiWebsite';
import { apiWrapper } from '../../services/apiWrapper';
import colors from '../../shared/colors';
import { ContactHeader } from '../../shared/fonts';
import HomeButton from '../button/HomeButton';
import { CaptchaWrapper } from '../contact/Contact';
import {AddModalContainer, ModalMask} from './joinTeamModal.styled';


const JoinTeamModal = ({onCancel}) => {

    const [from, setFrom] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const inputFile = useRef(null);
    const [file, setFile] = useState();

    const captchaRef = useRef(null);

  const handleSubmit = (e) => {
        //e.preventDefault();
        
        const token = captchaRef.current.getValue();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('from', from);
        formData.append('email', email);
        formData.append('role', subject);
        formData.append('message', message);

        getCaptchaToken(token).then(res => {
           apiWrapper(sendCvForm(formData), 'Application sent successfully', 'Error sending application').then(() => {
            onCancel();
           });
        }).catch(err => {
          console.log(err);
        });
        
        captchaRef.current.reset();
  }

  const handleCancel = () => {
    onCancel();
  };

  const onFileChange = (event) => {
    const { files } = event.target;

    if (files) {
      const validImageFile = files[0];

      if (validImageFile) {
        setFile(validImageFile);
        
      }
    }
  };

    return (
        <>
        <ModalMask>
            <AddModalContainer>
            <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
                style={{background: '#f4f7fa', padding: '1rem 1.2rem 1.8rem 1.2rem', borderRadius: '10px'}}
              >
                <ContactHeader style={{marginBottom: '1.2rem'}}>Send us your job application</ContactHeader>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={from}
                        id="name"
                        placeholder="Enter name"
                        required="required"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setFrom(e.target.value)} }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={email}
                        placeholder="Enter email"
                        required="required"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setEmail(e.target.value)} }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        size="40"
                        value={subject}
                        className="form-control"
                        id="company"
                        placeholder="Desired Position"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setSubject(e.target.value)} }
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginBottom: '1rem', marginLeft: '0'}}>
                    <HomeButton
                        label={'Upload CV'}
                        colorMain={colors.white}
                        colorHover={colors.mainBlue}
                        onClick={() => inputFile.current?.click()}
                    />
                    {file && <p style={{marginLeft: '1rem', marginTop: '0.5rem'}}>{file.name}</p>}
                    <input
                        style={{ visibility: 'hidden' }}
                        ref={inputFile}
                        type='file'
                        accept={['.doc', '.docx', 'application/pdf', 'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                        onChange={(e) => onFileChange(e)}
                        onClick={(e) => {
                            e.currentTarget.value = '';
                        }}
                    />
                </div>
                <p>Only PDF and Document files accepted!</p>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        value={message}
                        placeholder="Message"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setMessage(e.target.value)} }
                      ></textarea>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-sm-12 mt-3 row" style={{justifyContent: 'flex-start', gap: '0.4rem', marginLeft: '0'}}>
                    
                      
                    <HomeButton
                      label={'Send Message'}
                      onClick={handleSubmit}
                      colorMain={colors.white}
                      colorHover={colors.mainBlue}/>
                    <HomeButton
                      label={'Cancel'}
                      onClick={handleCancel}
                      colorMain={colors.white}
                      colorHover={colors.mainBlue}/>
                    
                  </div>
                  <CaptchaWrapper>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </CaptchaWrapper>
                </div>
              </form>
              </AddModalContainer>
            </ModalMask>
        </>
    );

    
};

export default JoinTeamModal;
