import React from "react";
import { BodyHeader24, BodyHeader30, BodyText12 } from "../../shared/fonts";
import { MvpIcon, ServicesIcon1, ServicesIcon2, ServicesIcon3, ServicesIcon4 } from "../../shared/icons";
import HomeButton from "../button/HomeButton";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-8">
              <div className="about-content-left section-heading">
                <BodyHeader30>
                  Our expertise
                </BodyHeader30>

                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap mb-2" style={{flexWrap: 'nowrap'}}>
                    <div className="mr-3">
                      <ServicesIcon3/>
                    </div>
                    <div className="mb-0">
                      <BodyHeader24 style={{marginBottom: '1.2rem'}}>Cloud & AI-based solutions</BodyHeader24>
                      <BodyText12>We develop scalable cloud-based and intelligent solutions focusing on long-term 
                      development and sustainability. We bring the best out of modern systems and new technologies, 
                      therefore maximizing value generation for our clients.</BodyText12>
                    </div>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2" style={{flexWrap: 'nowrap'}}>
                    <div className="mr-3">
                      <ServicesIcon4/>
                    </div>
                    <div className="mb-0">
                      <BodyHeader24 style={{marginBottom: '1.2rem'}}>Mobile App development</BodyHeader24>
                      <BodyText12>We develop interactive custom mobile applications. Our workflow covers everything from 
                      polishing your app idea and application design to development, testing, and deployment. 
                      We enable startups to scale their products and enterprises to dive their growth through our mobile solutions.</BodyText12>
                    </div>
                  </div>
                </div>

                <div className="single-feature mb-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1" style={{flexWrap: 'nowrap'}}>
                    <div className="mr-3">
                      <MvpIcon/>
                    </div>
                    <div className="mb-0">
                      <BodyHeader24 style={{marginBottom: '1.2rem'}}>MVP development</BodyHeader24>
                      <BodyText12>We are doing our part to help clients facilitate technology applications in their industry. 
                        We know the process of developing the MVP, and we help clients achieve their desired goals covering the entire process,
                        from MVP definition through designing, developing, and testing the MVP to releasing the MVP to users. 
                      </BodyText12>
                    </div>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2" style={{flexWrap: 'nowrap'}}>
                    <div className="mr-3">
                      <ServicesIcon2/>
                    </div>
                    <div className="mb-0">
                      <BodyHeader24 style={{marginBottom: '1.2rem'}}>Blockchain development</BodyHeader24>
                      <BodyText12>LittleBit IT has the expertise and experience in the field of blockchain technology. 
                      We provide complete support, product & software development for blockchain-based technology and its business value.</BodyText12>
                    </div>
                  </div>
                  <p></p>
                </div>
                
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-content-right">
                <img
                  src="assets/img/servicesMain.svg"
                  alt="about us"
                  className="img-fluid"
                  style={{height: '32rem'}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
