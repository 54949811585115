import React, { useState } from "react";
import colors from "../../shared/colors";
import { BodyHeader30, BodyHeader18, BodyText12 } from "../../shared/fonts";
import HomeButton from "../button/HomeButton";
import JoinTeamModal from "../modal/joinTeamModal";

export default function Team({ hasTitle, bgColor }) {

  const [showModal, toggleModal] = useState(false);

  const handleCancel = () => {
    toggleModal(false);
  };

  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-md-6">
              <div className="section-heading text-left mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Why Join Our Team
                  </span>
                ) : (
                  ""
                )}
                <BodyHeader30 style={{marginBottom: '1.2rem'}}>Why Join Our Team</BodyHeader30>
                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1">
                     <div className="mb-2">
                        <BodyText12>Before the value is delivered to our clients, first it is developed by our team. 
                              At LittleBit IT Sourcing, we promote an interesting work 
                              environment built around learning, cooperation, trust, 
                              respect, and integrity. At LittleBit, we believe perks are important, but the culture is crucial!
                        </BodyText12>
                      </div>
                  </div>
                </div>
                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1">
                     <div className="mb-2">
                        <BodyHeader18>
                        You will be valued
                        </BodyHeader18>
                        <BodyText12>
                        Your every effort will be valued and rewarded accordingly.
                        </BodyText12>
                      </div>
                  </div>
                </div>
                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1">
                     <div className="mb-2">
                        <BodyHeader18>
                        Professional Development
                        </BodyHeader18>
                        <BodyText12>
                        At LittleBit, you will learn more & improve your skills.
                        </BodyText12>
                      </div>
                  </div>
                </div>
                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1">
                     <div className="mb-2">
                        <BodyHeader18>
                        LittleBit's Flexibility
                        </BodyHeader18>
                        <BodyText12>
                        Your personal and professional preferences will be taken into account. We need you to be yourself because only then you can be happy at work.
                        </BodyText12>
                      </div>
                  </div>
                </div>
                <div className="single-feature mb-4 mt-4">
                  <div className="icon-box-wrap d-flex align-items-center mb-1">
                     <div className="mb-2">
                        <BodyHeader18>
                        Grow off our culture
                        </BodyHeader18>
                        <BodyText12>
                        We are not expecting you to ‘’blend in’’ completely. Instead, we will encourage you to help us make our culture even more positive.
                        </BodyText12>
                      </div>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <HomeButton
                      label={'Join our team'}
                      onClick={() => toggleModal(true)}
                      colorMain={colors.white}
                      colorHover={colors.mainBlue}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/team.svg"
                  alt="about us"
                  className="img-fluid"
                  style={{height: '32rem'}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && <JoinTeamModal onCancel={handleCancel}/>}
    </>
  );
}
