import { Id, toast } from 'react-toastify';

export const successNotification = (response) => {
  toast.success(response || 'Success', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'toast-message',
  });
};

export const errorNotification = (response) => {
  toast.error(response || 'Error has occured', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      width: '38.4rem',
      fontSize: '1.4rem',
      background: '#FEF2F2',
      lineHeight: '2rem',
      fontWeight: '500',
    },
  });
};

export const loadingNotification = (response) => {
  const id = toast.loading(response || 'Success', {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'toast-message',
    style: {
      width: '38.4rem',
      fontSize: '1.4rem',
      background: '#EFF6FF',
      lineHeight: '2rem',
      fontWeight: '500',
    },
  });
  return id;
};

export const updateNotificationSuccess = (id, response) => {
  toast.update(id, {
    render: response || 'Success',
    type: 'success',
    position: 'top-center',
    hideProgressBar: false,
    isLoading: false,
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'toast-message',
    style: {
      width: '28.4rem',
      fontSize: '1.2rem',
      background: '#ECFDF5',
      lineHeight: '2rem',
      fontWeight: '500',
    },
  });
  return id;
};

export const updateNotificationError = (id, response) => {
  toast.update(id, {
    render: response || 'Error',
    type: 'error',
    position: 'top-center',
    hideProgressBar: false,
    isLoading: false,
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'toast-message',
    style: {
      width: '28.4rem',
      fontSize: '1.2rem',
      background: '#FEF2F2',
      lineHeight: '2rem',
      fontWeight: '500',
    },
  });
  return id;
};
