import React from "react";
import styled from "styled-components";
import colors from "../../shared/colors";
import { BodyHeader18, BodyHeader30, BodyText10, BodyText12, PromoHeader12 } from "../../shared/fonts";
import { BespokeIcon, DeployIcon, DesignIcon, DevIcon, OutsourcingIcon, SoftwareProductDevIcon, SupportIcon } from "../../shared/icons";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100 lightBlue-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/processMain.svg"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <BodyHeader30 style={{marginBottom: '1.2rem'}}>Our formula for your success</BodyHeader30>
                <BodyText12 style={{marginBottom: '1.2rem'}}>
                LittleBit's software development services are provided with complete quality assurance & quality control. 
                By fully integrating with your team(s) and organization, our software engineers can deliver solutions with scalable, extensible architecture & design. 
                We do:
                </BodyText12>

                <div className="feature-content-wrap">
                  <List className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                        style={{padding: '0.5rem', height: '10.2rem', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}
                      >
                        <div className="row" style={{justifyContent: 'center', marginBottom: '0.3rem', height: '3.6rem'}}>
                          <SoftwareProductDevIcon/>
                        </div>
                        <BodyText10 style={{maxWidth: '8rem'}}>Software Product Development</BodyText10> 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                        style={{padding: '0.5rem', height: '10.2rem', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}
                      >
                        <div className="row" style={{justifyContent: 'center', marginBottom: '0.3rem', height: '3.6rem'}}>
                          <BespokeIcon/>
                        </div>
                        <BodyText10 style={{maxWidth: '8rem'}}>Bespoke Software Development</BodyText10> 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                        style={{padding: '0.5rem', height: '10.2rem', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}
                      >
                        <div className="row" style={{justifyContent: 'center', marginBottom: '0.3rem', height: '3.6rem'}}>
                          <OutsourcingIcon/>
                        </div>
                        <BodyText10 style={{maxWidth: '8rem'}}>Software Development Outsourcing Services</BodyText10> 
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                        style={{padding: '0.5rem', height: '10.2rem', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}
                      >
                        <div className="row" style={{justifyContent: 'center', marginBottom: '0.3rem', height: '3.6rem'}}>
                          <SupportIcon/>
                        </div>
                        <BodyText10 style={{maxWidth: '8rem'}}>Support & Consulting</BodyText10> 
                      </a>
                    </li>
                  </List>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                      <BodyText10>The process of bringing your idea to life with a software product is more than just coding. 
                        It requires a committed & proactive team who can take over the ownership of the project and cover the entire product development process.
                        LittleBit uses Agile methodology in product development, covering all aspects:</BodyText10>
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Software Product Management</p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Software Product Architecture</p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Design
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Development and Testing
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Technical Support
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Technical Maintenance and related expert services
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <BodyText10>
                        If you are looking for a unique software solution, developed according to your wishes and needs and you don't want to buy an "off the shelf" program, because it isn't fully adapted to your work methods, LittleBit can develop custom-made software just for you!
                        </BodyText10>
                    </div>
                    <div className="tab-pane" id="tab6-3">
                      <BodyText10 style={{marginBottom: '0.5rem'}}>LittleBit can provide you with an offshore/nearshore dedicated team of software engineers formed according to your needs, that works externally, but acts as an integral part of your organization.</BodyText10>
                      
                      <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-medall rounded mr-3 icon icon-color-2"></span>
                          <PromoHeader12 className="mb-0">Top 5</PromoHeader12>
                        </div>
                        <p>
                          With us as outsourcing partners, rest assured that you will get a reliable and qualified engineering team. 
                          Our talent pool and headquarters are located in Novi Sad (Republic of Serbia), which is ranked by Startup Genome as a Top 5 global ecosystem for affordable talent and a Top 5 global blockchain activation ecosystem.
                        </p>
                      </div>
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>
                            <PromoHeader12 className="mb-0">The "cost & time savings math"</PromoHeader12>
                          </div>
                          <p>
                          Make an executive decision to save up to 60% on finding, recruiting, onboarding, housing, and managing software engineers, legal and administrative work!
                          </p>
                        </div>
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                            <PromoHeader12 className="mb-0">Flexibility & Qualified Talent </PromoHeader12>
                          </div>
                          <p>
                          You may require specific talent profiles that your labor market lacks, but with us, you'll have immediate access to talented, committed, and bright professionals, without the legal and compliance hassle you would need to go through during the process of recruiting, hiring, and housing employees. 
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                      <p>
                      Our support team provides full-range reactive and proactive support activities to ensure maximum availability, stable functioning, and swift evolution of your app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const ItemBar = styled.div`
  background: ${colors.white}
  border: '1px solid #01193D';
  boxShadow: '0px 0px 5px #01193D';
  borderRadius: '10px'
`;

export const List = styled.ul`
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-wrap: nowrap !important;
  }

  @media (min-width: 505px) and (max-width: 767px) {
    flex-wrap: nowrap !important;
  }

  @media (min-width: 459px) and (max-width: 504px) {
    max-width: 80%;
  }
`;
