/* eslint-disable no-param-reassign */
import axios from 'axios';

export const AUTHORIZATION_HEADER_NAME = 'Authorization';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export default axiosInstance;
