import React from "react";
import { LocationIcon, MailIcon, SiteIcon } from "../../../shared/icons";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 pt-my`}
          style={{
            background:
              "url('assets/img/footerB.svg') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-2 mb-md-4 mb-sm-2 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logoWhite.svg"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                  Bringing Out the Best From Tech
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/company/littlebit-it/" target="_blank" title="Twitter">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.instagram.com/littlebit_it_sourcing/" target="_blank" title="Facebook">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <ul className="list-unstyled">
                        
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <ul className="list-unstyled support-list">
                        
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <ul className="list-unstyled support-list mt-4">
                        <li className="mb-3 d-flex align-items-center">
                          <LocationIcon style={{marginRight: '0.6rem'}} />
                            Novi Sad, Serbia
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <MailIcon style={{marginRight: '0.6rem'}} />
                          <a href="mailto:info@lbit.tech">
                            {" "}
                            info@lbit.tech
                          </a>
                        </li>
                        <li className="mb-3 d-flex align-items-center">
                          <SiteIcon style={{marginRight: '0.6rem'}} />
                          <a href="home"> www.lbit.tech</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © LittleBit IT Sourcing, All rights reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
