import styled from 'styled-components';
import colors from '../../shared/colors';
import { Column, Row } from '../../shared/layout';

export const ModalContainer = styled(Column)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;

  align-items: center;
  padding: 2.4rem;

  width: 51.2rem;
  background: #f4f7fa;

  box-shadow: 0rem 2rem 2.5rem -0.5rem rgba(0, 0, 0, 0.1),
    0rem 1rem 1rem -0.5rem rgba(0, 0, 0, 0.04);
  border-radius: 0.8rem;

  @media (max-width: 480px) {
    width: 90%;
    height: 23.5%;
    padding: 0rem;
    max-height: 84%;
    overflow-y: auto;
    top: 55%;
  }

  @media (max-width: 800px) {
    width: 85%;
    height: 23.5%;
    padding: 0rem;
  }

  @media (max-width: 340px) {
    height: 23.5%;
  }
`;

export const ModalMask = styled(Column)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 199;

  overflow: hidden;

  background: rgba(107, 114, 128, 0.75);
`;

export const AddModalContainer = styled(ModalContainer)`
  gap: 2.4rem;
  height: auto;

  @media (max-width: 480px) {
    gap: 0.2rem;
  }
`;

export const AddModalHeader = styled(Column)`
  align-items: center;
  gap: 0.8rem;
  width: 46.4rem;

  @media (max-width: 482px) {
    width: 100%;
  }
`;

export const ActionsWrapper = styled(Row)`
  align-items: flex-start;
  gap: 1.2rem;

  width: 100%;
  height: 3.8rem;
`;

