import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha"
import styled from 'styled-components';
import { getCaptchaToken, sendEmailForm } from '../../services/api/apiWebsite';
import { apiWrapper } from '../../services/apiWrapper';
import colors from "../../shared/colors";
import { BodyHeader30, BodyText12, ContactHeader } from "../../shared/fonts";
import HomeButton from "../button/HomeButton";

export default function Contact({ bgColor, ref }) {

  const [from, setFrom] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');


  const captchaRef = useRef(null);

  const handleSubmit = (e) => {
        //e.preventDefault();
        const data = {
          from: from,
          email: email,
          subject: subject,
          message: message
        }
        const token = captchaRef.current.getValue();

       
        getCaptchaToken(token).then(res => {
          apiWrapper(sendEmailForm(JSON.stringify(data)), 'Email sent successfully', 'Error sending email');
        }).catch(err => {
          console.log(err);
        });
        
        captchaRef.current.reset();
  }

  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "white-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <BodyHeader30 style={{marginBottom: '1.2rem'}}>Contact Us</BodyHeader30>
                <BodyText12>
                  If you have a question for us or if you want to engage us in your next project.
                </BodyText12>
              </div>
              <div className="footer-address">
                <BodyText12 style={{marginTop: '1.2rem', marginBottom: '1.2rem'}}>
                  Contact us using the form.
                </BodyText12>
                <BodyText12>We look forward to speaking with you</BodyText12>
                <ul>
                  <li>
                    <BodyText12 style={{marginTop: '1.2rem'}}>
                      Email:
                      <a href="mailto:info@lbit.tech">
                        info@lbit.tech
                      </a>
                    </BodyText12>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
                style={{background: '#f4f7fa', padding: '1rem 1.2rem 1.8rem 1.2rem', borderRadius: '10px'}}
              >
                <ContactHeader style={{marginBottom: '1.2rem'}}>Reach us quickly</ContactHeader>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={from}
                        id="name"
                        placeholder="Enter name"
                        required="required"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setFrom(e.target.value)} }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={email}
                        placeholder="Enter email"
                        required="required"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setEmail(e.target.value)} }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        size="40"
                        value={subject}
                        className="form-control"
                        id="company"
                        placeholder="Your Company"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setSubject(e.target.value)} }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        value={message}
                        placeholder="Message"
                        style={{border: '1px solid #01193D', borderRadius: '8px'}}
                        onChange={(e) => { setMessage(e.target.value)} }
                      ></textarea>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <HomeButton
                      label={'Send Message'}
                      onClick={handleSubmit}
                      colorMain={colors.white}
                      colorHover={colors.mainBlue}/>
                  </div>
                  <CaptchaWrapper>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </CaptchaWrapper>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const CaptchaWrapper = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`;
 