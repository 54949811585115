import React, { FC } from 'react';
import { Wrapper, LabelWrapper } from './HomeButton.styled';

const HomeButton = ({ label, onClick, className, colorMain, colorHover }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Wrapper onClick={handleClick} className={className} colorMain={colorMain} colorHover={colorHover}>
      <LabelWrapper>{label}</LabelWrapper>
    </Wrapper>
  );
};

export default HomeButton;
